<script>
	import { FormWizard, TabContent } from "vue-form-wizard"
	import "vue2-dropzone/dist/vue2Dropzone.min.css"
	import "vue-form-wizard/dist/vue-form-wizard.min.css"
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"
	import "vue-multiselect/dist/vue-multiselect.min.css"
	import Vue from 'vue';
	import axios from 'axios'
	import VueAxios from 'vue-axios'
	import { required, minLength } from "vuelidate/lib/validators"
	import VueTimepicker from "vue2-timepicker"
	import 'vue2-timepicker/dist/VueTimepicker.css'

	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

	/**
	 * Form-upload component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			Multiselect,
			FormWizard,
			TabContent,
			VueTimepicker,
		},
		page: {
			title: "Form File Upload",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		validations: {
			journeyName: {
				required,
			},
			documentFields: {
				minLength: minLength(1),
				$each: {
					name: {
						required
					},
					isUnique: function(currItem, itemArr) {
						var firstIdx = itemArr.findIndex((item) => currItem.name === item.name)
						if (currItem === itemArr[firstIdx]) {
							return true
						}
						return false
					}
				},
			},
		},
		data() {
			return {
				title: "Form File Upload",
				items: [
					{
						text: "Forms",
						href: "/",
					},
					{
						text: "Form File Upload",
						active: true,
					},
				],
				pageType: '',
				number: "",
				data: [],
				journeyId: null,
				jobRoles: [],
				journeyName: 'Common Template',
				firstActive: '',
				thirdActive: '',
				enabled: false,
				jobOptions: [
					"All",
					"Sales Manager",
					"Full Stack Developer",
					"Android Developer",
					"iOS Developer",
					"Backend Developer",
					"Accounts Manager",
					"Sales Executive",
					"HR",
				],
				document: null,
				docOptions: [
					"Offer Letter",
					"Pan Card",
					"Aadhaar Card",
					"Driving License"
				],
				day: null,
				dayOptions: [
					"0",
					"1 Day",
					"2 Days",
					"3 Days",
					"7 Days",
					"15 days",
					"30 days",
				],
				trigger: null,
				triggerOptions: [
					"After sending offer letter",
					"After offer acceptance",
					"After document verification",
					"Before Joining",
					"After Joining",
				],
				action: null,
				isJourneyNameExists: false,
				isOneDocumentSelected: true,
				actionOptions: [
					"Send Material",
					"Collect Information",
					"Collect Feedback",
				],
				rulesFields: [
					{
						id: 1,
						triggerTime: 'hh:mm A',
						isTriggerTimeError: false,
						isMaterialsError: false,
						materials: {
							id: 1,
							materialLink: '',
							materialInputText: '',
						},
						questions: [
							{
								id: 1,
								questionText: ''
							},
						],
						feedback: [
							{
								id: 1,
								questionText: '',
							},
						],
					},
				],
				faqFields: [
					{
						question: "",
						answer: "",
					},
				],
				documentFields: [
					{
						name: null,
						isVerificationRequired: false,
						isESignRequired: true
					},
				],
				file: '',
				showModal: false,
				onboardJourneyDetail: '',
			};
		},
		computed: {},
		mounted: function() {
			if (this.$route.params.type == 'edit') {
				this.journeyId = this.$route.params.journeyId
				this.pageType = 'edit'
				this.getJourneyDetails()
			} else if (this.$route.params.type == 'view') {
				this.journeyId = this.$route.params.journeyId
				this.pageType = 'view'
				this.getJourneyDetails()
			} else {
				this.journeyId = 0;
			}
		},
		methods: {
			addRoles() {},
			addFaqFormData() {
				this.faqFields.push({
					question: "",
					answer: "",
				});
			},
			addRulesFormData() {
				this.rulesFields.push({
					triggerTime: 'hh:mm A',
					materials: {
						materialLink: '',
						materialInputText: '',
					},
					questions: [],
				});
			},
			addDocumentFormData() {
				this.documentFields.push({
					name: null,
					isVerificationRequired: true,
					isESignRequired: true,
				});
			},
			addMoreQuestion: function(index, id) {
				this.rulesFields[index].questions.push({
					id: id + 1,
					questionText: '',
				});
			},
			addFeebackQuestion: function(index, id) {
				this.rulesFields[index].feedback.push({
					id: id + 1,
					questionText: '',
				})
			},
			deleteFeedbackQuestion: function(index, id) {
				this.rulesFields[index].feedback.splice(id, 1)
			},
			deleteQuestionRow: function(index, id) {
				this.rulesFields[index].questions.splice(id, 1)
			},
			/**
			 * Delete the row
			 */
			deleteFaqRow(index) {
				if (confirm("Are you sure you want to delete this element?"))
					this.faqFields.splice(index, 1);
			},
			deleteRulesRow(index) {
				if (confirm("Are you sure you want to delete this element?"))
					this.rulesFields.splice(index, 1);
			},
			deleteDocumentRow(index) {
				if (confirm("Are you sure you want to delete this element?"))
					this.documentFields.splice(index, 1);
			},
			handleFaqFileUpload(event) {
				this.file = event.target.files[0];
				let formData = new FormData();
				formData.append('file', this.file);
				//formData.append('corpBranchID', 1); //TODO change this to dynamic
				this.axios.post('onboarding-journey/upload-excel', //TODO change this url
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				).then((response) => {
					console.log(response.data.data);
					//this.faqFields = response.data.data;
					this.faqFields = [];
					for (let i = 0;i < response.data.data.length;i++) {
						let question = response.data.data[i].Question;
						this.faqFields.push({
							question: question,
						});
					}
				});
			},
			handleFileUpload: function(event, index) {
				this.file = event.target.files[0];
				let formData = new FormData();
				formData.append('file', this.file);
				this.axios.post('onboarding-journey/upload-excel', formData, {
					header: {
						'Content-Type': 'multipart/form-data',
					},
				}).then((response) => {
					this.rulesFields[index].questions = [];
					for (let i = 0;i < response.data.data.length;i++) {
						let question = response.data.data[i].Question;
						this.rulesFields[index].questions.push({
							questionText: question,
						});
					}
				});
			},
			uploadMaterial: function(event, index) {
				this.file = event.target.files[0];
				let formData = new FormData();
				formData.append('material', this.file);
				this.axios.post('onboarding-journey/upload-material', formData, {
					header: {
						'Content-Type': 'multipart/form-data',
					},
				}).then((response) => {
					console.log(response.data.data.url);
					this.rulesFields[index].materials.materialLink = response.data.data.url;
				});
			},
			addNewDocumentType(newDoc) {
				this.docOptions.push(newDoc);
				this.document = newDoc;
				// this.docOptions.at(index).Doc;
			},
			addDayOptions: function(newDay) {
				this.dayOptions.push(newDay)
			},
			addTriggerOptions: function(newTriggerOption) {
				this.triggerOptions.push(newTriggerOption)
			},
			addActionOptions: function(newActionOption) {
				this.actionOptions.push(newActionOption)
			},
			// Stepper form submit at every step
			onNextStep: function (tabIndex) {
				return new Promise((resolve, reject) => {
					if (tabIndex === 0) {
						if (this.pageType == 'edit') {
							console.log(this.pageType)
							this.axios.post('onboarding-journey/edit-roles', {
								onboardJourneyId: this.journeyId,
								corporateId: this.$storageData.corporateId,
								branchId: this.$storageData.branchId,
								journeyName: this.journeyName,
								//designation: this.jobRoles,
							}).then((result) => {
								console.log(result.data.data)
								let status = result.data.responseCode
								//console.log(result.data);
								if (status === 200) {
									this.journeyId = result.data.data.onboardJourneyDetail._id
									console.log(this.journeyId)
									//this.$v.reset()
									resolve(true)
								}
								if (status === 400) {
									//alert(result.data.response)
									//this.isJourneyNameExists = true
									this.$toast.error(result.data.response, {
										position: "top-right",
										timeout: 5000,
										closeOnClick: true,
										pauseOnFocusLoss: true,
										pauseOnHover: true,
										draggable: true,
										draggablePercent: 0.6,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: "button",
										icon: true,
										rtl: false
									})
									reject(false)
								}
							}).catch((error) => {
								console.log(error)
								reject(false)
							})
						} else if(this.pageType == 'view') {
							resolve(true)
						} else {
							this.axios.post('onboarding-journey/add-rules', {
								journeyName: this.journeyName,
								//designation: this.jobRoles,
								corporateId: this.$storageData.corporateId,
								branchId: this.$storageData.branchId,
							}).then((result) => {
								let status = result.data.responseCode
								//console.log(result.data);
								if (status == 200) {
									this.journeyId = result.data.data.onboardJourneyDetail._id
									console.log(this.journeyId)
									//this.$v.reset()
									resolve(true)
								}
								if (status === 400) {
									//alert(result.data.response)
									//this.isJourneyNameExists = true
									this.$toast.error(result.data.response, {
										position: "top-right",
										timeout: 5000,
										closeOnClick: true,
										pauseOnFocusLoss: true,
										pauseOnHover: true,
										draggable: true,
										draggablePercent: 0.6,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: "button",
										icon: true,
										rtl: false
									})
									reject(false)
								}
							}).catch((error) => {
								console.log(error)
								reject(false)
							});
						}
					}
					if (tabIndex === 1) {
						let checklist = [];
						//console.log(this.documentChecklist.length)
						this.documentFields.forEach(field => {
							if (field.name !== '' && field.name !== null) {
								checklist.push({
									name: field.name,
									isVerificationRequired: field.isVerificationRequired,
									isESignRequired: field.isESignRequired
								})
							}
							console.log(field.name)
						})
						console.log(this.documentFields.length)
						// //reject(false)
						// this.$v.documentFields.$each.name.touch()
						// if (this.$v.documentFields.$each.name.$invalid) {
						// 	reject(false)
						// }
						this.axios.post('onboarding-journey/add-document-checklist', {
							onboardJourneyId: this.journeyId,
							documentChecklist: checklist
						}).then((result) => {
							let status = result.data.responseCode
							console.log(result.data)
							if (status === 200) {
								console.log(result.data)
								resolve(true)
							}
							if (status === 400) {
								this.$toast.error(result.data.response, {
									position: "top-right",
									timeout: 5000,
									closeOnClick: true,
									pauseOnFocusLoss: true,
									pauseOnHover: true,
									draggable: true,
									draggablePercent: 0.6,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: "button",
									icon: true,
									rtl: false
								})
								reject(false);
							}
						}).catch((error) => {
							alert(error)
							reject(false)
						})
					}
					if (tabIndex === 2) {
						/*this.rulesFields.forEach(rules => {
							console.log("Material Link: " + rules.materials.materialLink);
							console.log("Material Text: " + rules.materials.materialInputText);
							rules.questions.forEach(question => {
								console.log("Question: " + question.question);
							});
						});*/
						console.log("Clicked")
						// let isError = false;
						// this.rulesFields.forEach(rules => {
						// 	if (rules.triggerTime === 'hh:mm A') {
						// 		rules.isTriggerTimeError = true
						// 		isError = true
						// 		return
						// 	} else if (rules.materials.materialLink === '') {
						// 		rules.isMaterialsError = true
						// 		isError = true
						// 		return
						// 	} else {
						// 		isError = false
						// 		return
						// 	}
						// });
						// if (isError) {
						// 	reject(false)
						// } else {
							console.log(this.$storageData.corporateId);
							console.log(this.$storageData.branchId);
							this.axios.post('onboarding-journey/add-roles-rules', {
								onboardJourneyId: this.journeyId,
								adminId: this.$storageData.id,
								corporateId: this.$storageData.corporateId,
								branchId: this.$storageData.branchId,
								rule: this.rulesFields,
							}).then((result) => {
								let status = result.data.responseCode;
								//console.log(result.data);
								if (status === 200) {
									console.log(result.data.data)
									this.$router.push('/onboarding/journey-lists')
									resolve(true)
								} 
								if (status === 400) {
									// this.$toast.error(result.data.response, {
									// 	position: "top-right",
									// 	timeout: 5000,
									// 	closeOnClick: true,
									// 	pauseOnFocusLoss: true,
									// 	pauseOnHover: true,
									// 	draggable: true,
									// 	draggablePercent: 0.6,
									// 	showCloseButtonOnHover: false,
									// 	hideProgressBar: false,
									// 	closeButton: "button",
									// 	icon: true,
									// 	rtl: false
									// })
									reject(false)
								}
							}).catch((error) => {
								alert(error);
								reject(false);
							});
						//}
					}
					/*if (tabIndex === 3) {
						console.log(this.faqFields);
						this.axios.post('onboarding-journey/add-faq', {
							onboardJourneyId: this.journeyId,
							faqList: this.faqFields,
						}).then((result) => {
							let status = result.data.responseCode;
							if (status == 200) {
								this.$router.push('/onboarding/journey-lists');
							} else {
								reject(false);
							}
						}).catch((error) => {
							alert(error);
							reject(false);
						});
					}*/
				});
			},
			filterDataByRange: function(which, text) {
				if (text === 'Yes') {
					this.documentFields[which].isVerificationRequired = true
					this.documentFields[which].isESignRequired = true
				}
				if (text === 'No') {
					this.documentFields[which].isVerificationRequired = false
					this.documentFields[which].isESignRequired = false
				}
			},
			actionChange: function(index) {
				this.showModal = true
				if (this.rulesFields[index].action === 'Collect Information') {
					this.$bvModal.show('collect-info' + (index + 1))
				}
				if (this.rulesFields[index].action === 'Collect Feedback') {
					console.log(index)
					this.$bvModal.show('collect-feedback' + (index + 1))
				}
			},
			closeModal: function(index) {
				this.showModal = false
				this.$bvModal.hide('collect-info' + (index + 1))
				this.$bvModal.hide('collect-feedback' + (index + 1))
			},
			getJourneyDetails: function() {
				this.axios.post('onboarding-journey/details', {
					onboardJourneyId: this.journeyId,
					corporateId: this.$storageData.corporateId,
					branchId: this.$storageData.branchId,
				}).then((result) => {
					console.log(result.data.data.onboardJourneyDetail)
					this.onboardJourneyDetail = result.data.data.onboardJourneyDetail
					this.journeyName = this.onboardJourneyDetail.journeyName
					this.jobRoles = this.onboardJourneyDetail.designation
					this.documentFields = this.onboardJourneyDetail.documentChecklist
					this.rulesFields = this.onboardJourneyDetail.rule
				}).catch((error) => {
					console.log(error)
				});
			},
		},
		middleware: "authentication",
	};
</script>
<style scoped>
	.page-content {
		min-height: 750px;
	}
	.modal-body{
		overflow: hidden;
		overflow-y: auto;
		max-height: 450px;
	}
	.float-left {
		float: left;
	}
	.float-right {
		float: right;
	}
	.modal-footer {
		justify-content: space-between;
	}
	.is-invalid {
		border-color: #f46a6a;
	}
	input.form-control, input.form-control:hover, input.form-control:focus {
		outline: 0 none;
		box-shadow: 0 none;
	}
	.vue__time-picker {
		width: 100%;
	}
	.vue__time-picker input.display-time {
		width: 100%;
		height: auto;
		padding: 0.47rem 0.75rem;
		font-size: 0.88rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.vue__time-picker input.display-time:focus,
	.vue__time-picker input.display-time:active {
		border: 1px solid #ced4da;
		outline: none;
		box-shadow: none;
	}
	.vue__time-picker-dropdown,
	.vue__time-picker .dropdown {
		top: auto;
	}
	.vue__time-picker-dropdown,
	.vue__time-picker .dropdown,
	.vue__time-picker-dropdown .select-list,
	.vue__time-picker .dropdown .select-list {
		width: 100%;
	}
	.vue__time-picker input.display-time.disabled,
	.vue__time-picker input.display-time:disabled {
		background: #ededed;
	}
	.timing.set-all span,
	.timing.set-all div.col-md-3 {
		display: inline-block;
	}
	.btn, .btn:hover, .btn:active, .btn:focus {
		background-color: transparent;
		color: #EB455F;
		outline: none;
		box-shadow: none;
	}
	.btn.active, .btn.active:hover, .btn.active:active, .btn.active:focus {
		background-color: #EB455F;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title"></h4>
						<form-wizard color="#EB455F">
							<!-- Journey Template Form START -->
							<tab-content :before-change="() => onNextStep(0)">
								<div class="row">
									<h4>Add Roles</h4>
									<div class="col-lg-12 align-self-center">
										<div class="form-group mb-4">
											<label class="col-md-3 col-form-label" for="journeyName">Journey Name*</label>
											<div class="col-md-6">
												<div class="mb-3">
													<input
														id="journeyName"
														v-model="journeyName"
														type="text"
														class="form-control"
														name="journeyName"
														:class="{ 'is-invalid': $v.journeyName.$error || isJourneyNameExists }"
														value="Common Template"
														:disabled="pageType == 'view' ? true : false" />
													<div v-if="$v.journeyName.$error || isJourneyNameExists" class="invalid-feedback">
														<div v-if="!$v.journeyName.required">Journey name is required.</div>
														<div v-if="isJourneyNameExists">Journey name already exist</div>
													</div>
												</div>
											</div>
										</div>
										<!-- <label class="col-md-3 col-form-label" for="jobRoles">Which designation is this journey for?</label>
										<div class="col-md-6">
											<div class="mb-3">
												<multiselect
													id="jobRoles"
													class=""
													name="jobRoles"
													v-model="jobRoles"
													:options="jobOptions"
													:multiple="true"
													:allow-empty="false"
													:disabled="pageType == 'view' ? true : false"
													:class="{ 'is-invalid': $v.jobRoles.$error }"></multiselect>
											</div>
										</div> -->
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>
							<!-- Journey Template Form END -->
							<!-- Document Checklist START -->
							<tab-content :before-change="() => onNextStep(1)">
								<div class="row">
									<h4>Add Document Checklist</h4>
									<div class="col-lg-12">
										<!-- <label class="col-md-3 col-form-label" for="userName">Documents*</label> -->
										<form class="repeater" enctype="multipart/form-data">
											<div v-for="(field, index) in documentFields" :key="field.id" class="row" :class="{ 'is-invalid': !isOneDocumentSelected }">
												<div class="mb-3 col-lg-3">
													<label for="name">Document</label>
													<multiselect
														v-model="field.name"
														:options="docOptions"
														class="helo"
														:show-labels="false"
														:taggable="true"
														@tag="addNewDocumentType"
														:multiple="false"
														:class="{ 'is-invalid': $v.documentFields.$each[index].name.$error }"
														:disabled="pageType == 'view' ? true : false" />
													<div v-if="$v.documentFields.$each[index].name.$error" class="invalid-feedback">
														<div v-if="$v.documentFields.$each[index].name.required">Select Document.</div>
													</div>
												</div>
												<div class="mb-3 col-lg-2">
													<label v-if="field.name == 'Offer Letter'">E-sign Required?</label>
													<label v-else>Verification Required?</label>
													<div class="check-group btn-group-toggle btn-group bv-no-focus-ring" style="height:auto;" role="group" aria-label="Date options">
														<button type="button" class="btn btn-outline-danger" :class="`${field.isVerificationRequired ? 'active' : ''}`" v-on:click="filterDataByRange(index, 'Yes')" :disabled="pageType == 'view' ? true : false">Yes</button>
														<button type="button" class="btn btn-outline-danger" :class="`${!field.isVerificationRequired ? 'active' : ''}`" v-on:click="filterDataByRange(index, 'No')" :disabled="pageType == 'view' ? true : false">No</button>
													</div>
												</div>
												<div class="col-lg-1 align-self-center">
													<button type="button" class="btn btn-danger btn-block" value="Delete" @click="deleteDocumentRow(index)" :disabled="pageType == 'view' ? true : false">
														<i class="mdi mdi-delete-outline"></i>
													</button>
												</div>
											</div>
											<div v-if="!isOneDocumentSelected" class="invalid-feedback">
												<div>Atleast select one document.</div>
											</div>
											<input type="button" class="btn btn-danger mt-3 mt-lg-0" value="Add New Document" @click="addDocumentFormData" :disabled="pageType == 'view' ? true : false" />
										</form>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>
							<!-- Document Checklist Form END -->
							<!-- Rules START -->
							<tab-content :before-change="() => onNextStep(2)">
								<div class="row">
									<h4>Add Rules</h4>
									<div class="col-lg-12">
										<!-- <label class="col-md-3 col-form-label" for="userName">Documents*</label> -->
										<form class="repeater" enctype="multipart/form-data">
											<div v-for="(field, index) in rulesFields" :key="field.id" class="row">
												<div class="mb-3 col-lg-2">
													<label for="name">Days</label>
													<multiselect
														v-model="field.day"
														:options="dayOptions"
														class="helo"
														:taggable="true"
														@tag="addDayOptions"
														:show-labels="false"
														:disabled="pageType == 'view' ? true : false" />
												</div>
												<div class="mb-3 col-lg-2">
													<label for="name">Trigger</label>
													<multiselect
														v-model="field.trigger"
														:options="triggerOptions"
														:taggable="true"
														@tag="addTriggerOptions"
														class="helo"
														:show-labels="false"
														:disabled="pageType == 'view' ? true : false" />
												</div>
												<div class="mb-3 col-lg-2">
													<label for="name">Trigger Time</label>
													<vue-timepicker
														v-model="field.triggerTime"
														class="helo"
														format="hh:mm A"
														hour-label="Hour"
														minute-label="Miniutes"
														apm-label="AM/PM"
														close-on-complete
														hide-clear-button
														:class="{ 'is-invalid': field.isTriggerTimeError }"
														drop-direction="auto" />
													<div v-if="field.isTriggerTimeError" class="invalid-feedback">
														<div v-if="field.triggerTime === 'hh:mm A'">Select trigger time.</div>
													</div>
												</div>
												<div class="mb-3 col-lg-2">
													<label for="name">Action</label>
													<multiselect
														v-model="field.action"
														:options="actionOptions"
														:taggable="true"
														@tag="addActionOptions"
														class="helo"
														:show-labels="false"
														:disabled="pageType == 'view' ? true : false" />
												</div>
												<div class="mb-3 col-lg-2" v-if="field.action === 'Send Material'">
													<label for="resume">Attach Material</label>
													<input id="resume" type="file" @change="uploadMaterial($event, index)" class="form-control-file" :class="{ 'is-invalid': field.isMaterialsError }" :disabled="pageType == 'view' ? true : false" />
													<div v-if="field.isMaterialsError" class="invalid-feedback">Attach Material</div>
													<div v-if="pageType == 'view'"></div>
												</div>
												<div class="mb-3 col-lg-2" v-if="field.action === 'Send Material'">
													<label for="message">Text Input(Optional)</label>
													<textarea id="message" v-model="field.materials.materialInputText" class="form-control"></textarea>
												</div>
												<div class="md-3 col-lg-2 align-self-center" v-if="field.action === 'Collect Information'">
													<button type="button" class="btn btn-danger btn-block" value="Collect Inofrmation" @click="actionChange(index)">Collect Information</button>
												</div>
												<div class="md-3 col-lg-2 align-self-center" v-if="field.action === 'Collect Feedback'">
													<button type="button" class="btn btn-danger btn-block" value="Collect Feedback" @click="actionChange(index)">Collect Feedback</button>
												</div>
												<div class="col-lg-1 align-self-center">
													<button type="button" class="btn btn-danger btn-block" value="Delete" @click="deleteRulesRow(index)" :disabled="pageType == 'view' ? true : false">
														<i class="mdi mdi-delete-outline"></i>
													</button>
												</div>
												<b-modal v-bind:id="'collect-feedback' + (index + 1)" class="modal fade">
													<template #modal-title>Collect Feedback</template>
													<div class="d-flex flex-column">
														<div class="mb-3 col-lg-12">
															<label for="feedback">Browse File</label>
															<input type="file" id="feedback" class="form-control-file" />
															<br />
															<a href="">Download Sample</a>
														</div>
														<h4 class="text-center">OR</h4>
														<div v-for="(question, ind) in field.feedback" :key="question.id" class="mb-3 d-flex col-md-12">
															<div class="col-lg-12">
																<label v-bind:for="'feedback' + (ind + 1)" class="col-md-12 col-form-label">Question {{ ind + 1 }}</label>
																<div class="d-flex flex-row justify-content-between">
																	<div class="col-md-10">
																		<input type="text" :id="'feedback' + (ind + 1)" v-model="question.questionText" class="form-control" :disabled="pageType == 'view' ? true : false" />
																	</div>
																	<div class="col-md-1">
																		<button class="btn btn-danger btn-block" value="Delete" @click="deleteFeedbackQuestion(index, id)" :disabled="pageType == 'view' ? true : false">
																			<i class="mdi mdi-delete-outline"></i>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<template #modal-footer>
														<button type="button" class="btn btn-danger" @click="addFeebackQuestion(index, field.feedback.length)" :disabled="pageType == 'view' ? true : false"><i class="mdi mdi-plus mr-1"></i> Add more question</button>
														<button type="button" class="btn btn-danger" @click="() => closeModal(index)">Submit</button>
													</template>
												</b-modal>
												<b-modal v-bind:id="'collect-info' + (index + 1)" class="modal fade">
													<template #modal-title>Collect Information</template>
													<div class="modal-body" ref="modalBody">
														<div class="row">
															<div class="mb-3 col-lg-12">
																<label for="resume">Browse File</label>
																<input id="resume" type="file" class="form-control-file" @change="handleFileUpload($event, index)" />
																<br />
																<a href="localhost:8000/">Download Sample</a>
															</div>
															<h4 class="text-center">OR</h4>
															<div v-for="(question, ind) in field.questions" :key="question.id" class="row">
																<div class="mb-3 col-lg-12 row">
																	<label v-bind:for="'question' + (ind + 1)" class="col-md-12 col-form-label">Question {{ind + 1}}</label>
																	<div class="col-md-11">
																		<input type="text" :id="'question' + (ind + 1)" class="form-control" v-model="question.questionText" :disabled="pageType == 'view' ? true : false" />
																	</div>
																	<div class="col-md-1">
																		<button type="button" class="btn btn-danger btn-block" value="Delete" @click="deleteQuestionRow(index, ind)" :disabled="pageType == 'view' ? true : false">
																			<i class="mdi mdi-delete-outline"></i>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<template #modal-footer>
														<button class="btn btn-danger float-left" @click="() => addMoreQuestion(index, field.questions.length)" :disabled="pageType == 'view' ? true : false"><i class="mdi mdi-plus mr-1"></i>  Add more question</button>
														<button type="button" class="btn btn-danger float-right" @click="() => closeModal(index)">Submit</button>
													</template>
												</b-modal>
											</div>
											<!-- <input type="button" class="btn btn-success mt-3 mt-lg-0" value="Add New Document"  /> -->
											<b-button class="btn btn-soft-danger btn-danger" variant="danger" @click="addRulesFormData" :disabled="pageType == 'view' ? true : false">Add New <i class="uil uil-arrow-right ms-2"></i></b-button>
										</form>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>
							<!-- Rules Form END -->
							<!-- FAQ START -->
							<!-- <tab-content :before-change="() => onNextStep(3)">
								<div class="row">
									<h4>Add FAQ</h4>
									<div class="col-lg-12">
										<div class="mb-3 col-lg-2">
											<label for="resume">Browse File</label>
											<input id="resume" type="file" class="form-control-file" @change="handleFaqFileUpload($event)" />
											<a href="localhost:8000/">Download Sample</a>
										</div>
										<h4>OR</h4>
										<form class="repeater" enctype="multipart/form-data">
											<div v-for="(field, index) in faqFields" :key="field.id" class="row">
												<div class="mb-3 col-lg-4">
													<label for="message">Question</label>
													<textarea id="message" v-model="field.question" class="form-control"></textarea>
												</div>
												<div class="mb-3 col-lg-4">
													<label for="message">Answer</label>
													<textarea id="message" v-model="field.answer" class="form-control"></textarea>
												</div>
												<div class="col-lg-1 align-self-center">
													<button type="button" class="btn btn-danger btn-block" value="Delete" @click="deleteFaqRow(index)">
														<i class="mdi mdi-delete-outline"></i>
													</button>
												</div>
											</div>
											<input type="button" class="btn btn-danger mt-3 mt-lg-0" value="Add New" @click="addFaqFormData" />
										</form>
									</div>
								</div>
							</tab-content> -->
						</form-wizard>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end card -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>